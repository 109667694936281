.animateFadeIn {
  opacity: 0;
}
.animateTitle h2 {
  position: relative;
  animation: animate-title 2s linear;
}
.animateMoveIn > div > div .column-blocks-wrapper:nth-child(odd) {
  right: 100%;
}
.animateMoveIn > div > div .column-blocks-wrapper:nth-child(even) {
  left: 100%;
}
@keyframes animate-title {
  0% {
    bottom: -500px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
