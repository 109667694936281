/* Make tabs block horizontally scrollable on mobile */
/* liechti-tabs is a custom class set via block styling */
.liechti-tabs .tabs-block .tabs .ui.menu.center {
  overflow-y: hidden;
  overflow-x: visible;
  justify-content: left !important;
}
/* Min width is the size of the actual block in deployment */
@media only screen and (min-width: 770px) {
  .liechti-tabs .tabs-block .tabs .ui.menu.center {
    overflow-y: initial;
    overflow-x: initial;
    justify-content: center !important;
  }
}
/* Override DSGVO cookie settings button color */
.ui.container form.ui .ui.button.branded.olive {
  background-color: #D8140A;
}
